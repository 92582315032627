<template>
  <template v-if="tabs.find(tab => tab.route === route.name)">
    <card class="dashboard-margin mb-0" header-classes="border-radius-top-stevie" body-classes="pt-0">
      <div class="nav-wrapper pt-0">
        <div class="flex-column flex-md-row tabs-stevie">
          <ul
            class="flex-column flex-md-row nav nav-pills"
          >
            <li v-for="tab in tabs"  :key="tab.name" class="nav-item pr-0">
                <router-link :to="{name: tab.route}" exact-active-class="active" class="nav-link border-radius-bottom-stevie border-radius-top-none tabs-stevie shadow-none" >
                  {{tab.name}}
                </router-link>
            </li>
          </ul>
        </div>
      </div>
      <card class="shadow-none" body-classes="px-0">
        <router-view ></router-view>
      </card>
    </card>
  </template>
  <router-view v-else></router-view>
</template>
<script>
import { useI18n } from "vue-i18n/index";
import { onMounted, shallowRef } from "vue";
import { useRoute } from 'vue-router';


export default {
  name: "LandingLayout",
  setup() {
    const { t } = useI18n();
    const route = useRoute()
    const tabs = shallowRef([
      {
        name: t('tab.landingTemplates'),
        route: 'LandingsList',
      },
      {
        name: t('templates'),
        route: 'LandingsTemplates',
      },
    ])
    onMounted(()=>{
    })

    return {
      tabs,
      route
    }
  }
};
</script>
<style scoped lang="scss">
</style>
